import React, {useEffect, useMemo, useState} from "react"
import {observer} from "mobx-react"
import {Checkbox} from "@mui/material"
import {getComp} from "../../../utils/utils/DI"
import {useIntl} from "react-intl"
import CustomTable from "../../../utils/components/CustomTable/CustomTable"
import ReportIconComponent from "./ReportIconComponent"
import Box from "@mui/material/Box"
import DatesStore from "../../../stores/DatesStore"
import Typography from "@mui/material/Typography"
import {isLooker} from "../../../services/AccountService"

function ReportsSummeryTable({store, openReportDetails}) {
    const intl = useIntl()
    const [dateStore] = useState(new DatesStore())
    const dealersStore = getComp("DealersStore")
    const {
        collection,
        selectReport,
        selectAllReports,
        selectedReports,
        isAllReportsSelected,
        totalOrders,
        totalReports
    } = store

    

    const columns = [
        {
            propName: "checkBox",
            headerElement: isLooker() ? null : (
                <Checkbox
                    sx={{
                        width: "16px"
                    }}
                    checked={isAllReportsSelected}
                    onChange={() => selectAllReports()}
                    disabled={!collection.length}
                    onClick={(e) => e.stopPropagation()}
                />
            ),
            width: "30px",
            minWidth: "30px",
            renderCell: (params) => {
                return params.totalRow || isLooker() ? (
                    <Box sx={{height: "32px"}}></Box>
                ) : (
                    <Checkbox
                        sx={{
                            width: "16px"
                        }}
                        checked={selectedReports.some((el) => el === params.authorId)}
                        onChange={() => selectReport(params.authorId)}
                        onClick={(e) => e.stopPropagation()}
                    />
                )
            }
        },
        {
            propName: "openRow",
            minWidth: "40px",
            width: "40px"
        },
        {
            propName: "authorId",
            headerName: intl.formatMessage({id: "Исполнитель"}),
            headerStart: true,
            minWidth: "160px",
            renderCellValue: (params) => (params.totalRow ? " " : dealersStore.getDealerName(params.authorId)),
            fontWeight: 600,
            justifyContent: "flex-start",
            textAlign: "start"
        },
        {
            propName: "",
            headerName: intl.formatMessage({id: "Отчетный период"}),
            minWidth: "160px",
            renderCellValue: () => intl.formatMessage({id: "ИТОГО"}),
            headerStart: true,
            justifyContent: "flex-start",
            fontWeight: 600
        },
        {
            propName: "totalOrders",
            headerName: intl.formatMessage({id: "Всего установок"}),
            minWidth: "160px",
            renderCellValue: (params) => (params.totalRow ? totalOrders : params.totalOrders),
            fontWeight: 600
        },
        {
            propName: "totalOrders",
            headerName: intl.formatMessage({id: "Всего отчетов"}),
            minWidth: "160px",
            renderCellValue: (params) => (params.totalRow ? totalReports : params.totalReports),
            fontWeight: 600
        },
        {
            propName: "reports",
            headerName: intl.formatMessage({id: "Отчеты"}),
            minWidth: "500px",
            renderCell: () => ""
        }
    ]

    const additionalRowCells = [
        {},
        {},
        {},
        {
            renderCellValue: (data) => dateStore.getMonthName(data.month) + " " + data.year,
            justifyContent: "flex-start"
        },
        {renderCellValue: (data) => data.totalOrdersForDate},
        {renderCellValue: (data) => data.totalReportsForDate},
        {
            renderCell: (data) => (
                <Box
                    sx={{
                        minWidth: "500px",
                        maxWidth: "1000px",
                        width: "100%",
                        display: "flex",
                        flexWrap: "wrap",
                        gap: "4px"
                    }}
                >
                    {data.reports.map((report) => {
                        return (
                            <ReportIconComponent
                                openReportDetails={openReportDetails}
                                key={report.id}
                                report={report}
                                store={store}
                            />
                        )
                    })}
                </Box>
            )
        }
    ]
    const rows = useMemo(() => [...collection, {totalRow: true}], [collection])
   

    return (
        <CustomTable
            rows={rows}
            columns={columns}
            showPagination={false}
            total={collection.length}
            additionalRowPropName={"collection"}
            additionalRowCells={additionalRowCells}
            closeAdditionalInfo={true}
            toggleIsRowOpen={store.toggleIsRowOpen}
        />
    )
}

export default observer(ReportsSummeryTable)
