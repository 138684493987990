import {getStyles} from "../../../utils/utils/commonUtils"
import chipsStyles from "../../../components/Chips/styles/chipsStyles"
import {getComp} from "../../../utils/utils/DI"
import React, {useCallback, useState} from "react"
import Box from "@mui/material/Box"
import {css} from "@emotion/css"
import Typography from "@mui/material/Typography"
import Chip from "../../../components/Chips/Chip"
import DatesStore from "../../../stores/DatesStore"

function ReportsSummeryChip({onClick, propName, value}) {
    const classes = getStyles(chipsStyles)
    const {getReportStatusImage, getReportStatusName,} = getComp("StatusesStore")
    const {getDealerName} = getComp("DealersStore")
    const [datesStore] = useState(new DatesStore())
    const {getMonthName} = datesStore

    const setChipAction = useCallback(() => {
        return onClick ? () => onClick(propName, value) : null
    }, [value])

    const setValue = useCallback(() => {
        switch (propName) {
            case ("reportStatuses"): {
                return <Box className={"aln-center"}>
                    <img alt={""} src={getReportStatusImage(value)} className={css(classes.statusIcon)}/>
                    <Typography variant={"body1"} sx={classes.chipText}>
                        {getReportStatusName(value)}
                    </Typography>
                </Box>
            }
            case ("reportMonths"): {
                return getMonthName(value, false, true)
            }
            case ("dealers"): {
                return getDealerName(value)
            }
            default: {
                return value
            }
        }
    }, [value])

    return <Chip action={setChipAction()} value={setValue()}/>
}

export default ReportsSummeryChip