import {observer} from "mobx-react"
import {FormattedMessage, useIntl} from "react-intl"
import {closeModalFn, openModalFn} from "../../../utils/controllers/ModalController"
import MenuItem from "@mui/material/MenuItem"
import Box from "@mui/material/Box"
import Typography from "@mui/material/Typography"
import moment from "moment/moment"
import avatarIcon from "../../../assets/images/common/avatar.svg"
import React, {useState} from "react"
import {getComp} from "../../../utils/utils/DI"
import {getStyles} from "../../../utils/utils/commonUtils"
import reportsSummeryStyles from "../styles/reportsSummeryStyles"
import NewFilters from "../../../components/NewFilters/NewFilters"
import ReportsSummeryChip from "./ReportsSummeryChip"
import DatesStore from "../../../stores/DatesStore"
import Button from "@mui/material/Button"
import downloadWhite from "../../../assets/images/common/downloadWhite.svg"
import downloadGray from "../../../assets/images/common/downloadGray.svg"
import {getYears} from "../../../helpers/getYears"

function ReportsSummaryFilters({store, filtersStore}) {
    const classes = getStyles(reportsSummeryStyles)
    const intl = useIntl()
    const statusesStore = getComp("StatusesStore")
    const dealersStore = getComp("DealersStore")
    const [datesStore] = useState(new DatesStore())
    const {getMonthName} = datesStore

    const filtersData = [
        [
            {
                type: "select",
                propName: "reportStatuses",
                name: intl.formatMessage({id: "Статус"}),
                placeholder: intl.formatMessage({id: "Статус"}),
                collection: statusesStore.reportStatuses.filter((el) => !!el.id),
                setMenuItem: (data) => (
                    <MenuItem value={data.id === 0 ? "" : data.id} key={data.id}>
                        <Box className={"aln-center"}>
                            <img alt={""} src={data.imageSrc} />
                            <Box ml={1} sx={classes.selectTextContainer}>
                                <Typography variant={"subtitle2"} sx={classes.selectText}>
                                    {data.name}
                                </Typography>
                            </Box>
                        </Box>
                    </MenuItem>
                ),
                multiple: true
            },
            {
                type: "select",
                propName: "dealers",
                name: intl.formatMessage({id: "Дилер"}),
                placeholder: intl.formatMessage({id: "Льготные категории"}),
                collection: dealersStore.collection,
                setMenuItem: (data) => (
                    <MenuItem value={data.subjectId} sx={{padding: "20px 12px"}}>
                        <Box className={"aln-center"} height={20}>
                            <img alt={""} src={avatarIcon} height={20} width={20} />
                            <Box ml={1} sx={classes.selectTextContainer}>
                                <Typography
                                    variant={"body2"}
                                    sx={{...classes.selectTextContainer, ...classes.selectText}}
                                >
                                    {data.displayName}
                                </Typography>
                            </Box>
                        </Box>
                    </MenuItem>
                ),
                multiple: true
            },
            [
                {
                    type: "select",
                    propName: "reportMonths",
                    name: intl.formatMessage({id: "Месяц"}),
                    placeholder: intl.formatMessage({id: "Месяц"}),
                    setMenuItem: (data) => <MenuItem value={data}>{getMonthName(data, false, true)}</MenuItem>,
                    collection: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
                    width: "70%",
                    multiple: true
                },
                {
                    type: "select",
                    propName: "reportYears",
                    name: intl.formatMessage({id: "Год"}),
                    placeholder: intl.formatMessage({id: "Год"}),
                    collection: getYears(),
                    width: "30%",
                    multiple: true
                }
            ]
        ]
    ]

    const onSearch = async () => {
        openModalFn["progress-backdrop"]()
        const param = {}
        for (const key in filtersStore.data) {
            if (!!filtersStore.data[key]) {
                param[key] = filtersStore.data[key]
            }
        }
        await store
            .reLoad(param)
            .then(() => {
                filtersStore.setSelectedFilters()
            })
            .finally(() => closeModalFn["progress-backdrop"]())
    }

    const handleDeleteChip = (propName, multiselectValue) => {
        filtersStore.deleteFilter(propName, multiselectValue)
        onSearch()
    }

    const setChip = (params, withAction) => <ReportsSummeryChip {...params} onClick={withAction && handleDeleteChip} />
    return (
        <NewFilters
            filtersStore={filtersStore}
            filtersData={filtersData}
            onSearch={onSearch}
            setChip={setChip}
            filtersContainerHeight={"150px"}
            title={intl.formatMessage({id: "Результаты поиска"})}
            actionButtonProps={{
                name: intl.formatMessage({id: "Сохранить в файл"}),
                disabled: !store.selectedReports.length,
                onClick: store.loadFile,
                icon: store.selectedReports.length ? downloadWhite : downloadGray
            }}
        />
    )
}

export default observer(ReportsSummaryFilters)
