import PagedCollectionStore from '../../../utils/stores/PagedCollectionStore'
import { getReportsStatistics } from '../../../services/ApiService'
import { action, computed, makeObservable, observable } from 'mobx'
import { gState } from '../../../utils/controllers/GlobalStateController'
import { getComp } from '../../../utils/utils/DI'
import DatesStore from '../../../stores/DatesStore'

class ReportSummeryStore extends PagedCollectionStore {
    selectedReports = []
    totalOrders = 0
    totalReports = 0
    dateStore = new DatesStore()
    cast = []

    constructor() {
        super({ action: getReportsStatistics })
        makeObservable(this, {
            cast: observable,
            makeCast: action,
            selectedReports: observable,
            totalOrders: observable,
            totalReports: observable,
            isAllReportsSelected: computed,
            selectReport: action,
            selectAllReports: action,
            addServiceProps: action,
        })
    }

    makeCast = () => {
        this.cast = []
        this.collection.map((el) => {
            this.cast.push({
                authorId: el.authorId,
                isRowOpen: el.isRowOpen,
            })
        })
    }

    setData(data) {
        let newData = []
        const dealers = {}
        let totalSummeryReports = 0
        let totalSummeryOrders = 0
        const dealerStore = getComp('DealersStore')
        const dealersNames = dealerStore.collection
        for (let i = 0; i < data.length; i++) {
            const dataItem = data[i]
            const template = {
                authorId: null,
                displayName: '',
                totalReports: 0,
                totalOrders: 0,
                collection: [],
            }
            const reportItemTemplate = {
                month: null,
                year: null,
                totalReportsForDate: 0,
                totalOrdersForDate: 0,
                reports: [],
            }
            let reportTemplate = {
                authorId: null,
                id: null,
                month: null,
                ordersCount: 0,
                status: null,
                year: null,
                orders: [],
            }
            const setReportItemData = () => {
                reportItemTemplate.month = dataItem.month
                reportItemTemplate.year = dataItem.year
                reportItemTemplate.totalReportsForDate += 1
                reportItemTemplate.totalOrdersForDate += dataItem.ordersCount
            }

            const setReportData = () => {
                reportTemplate.id = dataItem.id
                reportTemplate.status = dataItem.status
                reportTemplate.month = dataItem.month
                reportTemplate.year = dataItem.year
                reportTemplate.ordersCount = dataItem.ordersCount
                reportTemplate.orders.push({ ordersCount: dataItem.ordersCount, orderStatus: dataItem.orderStatus })
            }
            if (!dealers[dataItem.authorId]) {
                template.authorId = dataItem.authorId
                template.totalReports += 1
                template.totalOrders += dataItem.ordersCount

                dealersNames.forEach((dealerName) => {
                    if (template.authorId === dealerName.subjectId) {
                        template.displayName = dealerName.displayName
                    }
                })

                setReportItemData()
                setReportData()
                reportItemTemplate.reports.push(reportTemplate)
                template.collection.push(reportItemTemplate)
                newData.push(template)
                dealers[dataItem.authorId] = true
                totalSummeryReports += 1
                totalSummeryOrders += dataItem.ordersCount
            } else {
                const dealerData = newData.find((el) => el.authorId === dataItem.authorId)
                const reportItem = dealerData.collection.find(
                    (el) => el.month === dataItem.month && el.year === dataItem.year
                )
                if (reportItem) {
                    const report = reportItem.reports.find((report) => report.id === dataItem.id)
                    if (report) {
                        report.orders.push({ orderStatus: dataItem.orderStatus, ordersCount: dataItem.ordersCount })
                        report.ordersCount += dataItem.ordersCount
                    } else {
                        setReportData()
                        reportItem.reports.push(reportTemplate)
                        reportItem.totalReportsForDate += 1
                        dealerData.totalReports += 1
                        totalSummeryReports += 1
                    }
                    reportItem.totalOrdersForDate += dataItem.ordersCount
                } else {
                    setReportItemData()
                    setReportData()
                    reportItemTemplate.reports.push(reportTemplate)
                    dealerData.collection.push(reportItemTemplate)
                    dealerData.totalReports += 1
                    totalSummeryReports += 1
                }
                dealerData.totalOrders += dataItem.ordersCount
                totalSummeryOrders += dataItem.ordersCount
            }
        }
        this.totalReports = totalSummeryReports
        this.totalOrders = totalSummeryOrders

        function deleteSubstr(str, substr1, substr2) {
            return str.toLowerCase().replace(substr1, '').replace(substr2, '').trim()
        }

        newData = newData.sort((a, b) => {
            let aName = deleteSubstr(a.displayName, 'ип', 'ооо')
            let bName = deleteSubstr(b.displayName, 'ип', 'ооо')

            if (aName < bName) {
                return -1
            }
            if (aName > bName) {
                return 1
            }
            return 0
        })

        super.setData(newData)
    }

    selectReport = (authorId) => {
        if (this.selectedReports.some((el) => el === authorId)) {
            this.selectedReports = this.selectedReports.filter((el) => el !== authorId)
        } else {
            this.selectedReports = [...this.selectedReports, authorId]
        }
    }

    selectAllReports = () => {
        if (this.selectedReports.length === this.collection.length) {
            this.selectedReports = []
        } else {
            this.selectedReports = this.collection.map((el) => el.authorId)
        }
    }

    get isAllReportsSelected() {
        return this.collection && this.selectedReports.length === this.collection.length
    }

    loadFile = () => {
        const intl = gState['intl']
        const fields = [
            [
                intl.formatMessage({ id: 'Дилер' }),
                intl.formatMessage({ id: 'Отчетный период' }),
                intl.formatMessage({ id: 'Всего заявок' }),
                intl.formatMessage({ id: 'Всего отчетов' }),
                intl.formatMessage({ id: 'Отчеты' }),
            ],
        ]
        let totalOrdersSummery = 0
        let totalReportsSummery = 0
        this.selectedReports.forEach((authorId, i) => {
            const dealerName = getComp('DealersStore').getDealerName(authorId)
            const dealerSummery = this.collection.find((dealerSummery) => dealerSummery.authorId === authorId)
            const { collection, totalOrders, totalReports } = dealerSummery
            totalOrdersSummery += totalOrders
            totalReportsSummery += totalReports
            collection.forEach((summeryForMonth, i) => {
                const { reports, month, year, totalOrdersForDate, totalReportsForDate } = summeryForMonth
                const reportIds = reports.map((el) => el.id)
                fields.push([
                    i === 0 ? dealerName : '',
                    `${this.dateStore.getMonthName(month)} ${year}`,
                    totalOrdersForDate,
                    totalReportsForDate,
                    reportIds.join(', '),
                ])
            })
            fields.push(['', intl.formatMessage({ id: 'ИТОГО' }), totalOrders, totalReports])
            if (i + 1 === this.selectedReports.length) {
                fields.push([intl.formatMessage({ id: 'ИТОГО' }), '', totalOrdersSummery, totalReportsSummery])
            }
        })

        const blobData = fields.map((el) => el.join(window.SAT.config.csvParams.divider)).join('\n')
        const data = new TextEncoder('utf-16be').encode(blobData)
        const blob = new File(['\uFEFF', data], intl.formatMessage({ id: 'Заявки' }), {
            type: `text/csv;charset=utf-8`,
        })
        window.location = window.URL.createObjectURL(blob)
        this.selectedReports = []
    }

    addServiceProps = () => {
        if (this.cast.length) {
            this.collection.map((el) => {
                const correspondingCast = this.cast.find(castEl => castEl.authorId === el.authorId);
                if (correspondingCast) {
                    el.isRowOpen = correspondingCast.isRowOpen;
                }
            })
        } else {
            this.collection.map((el) => {
                el.isRowOpen = false
               
            })
        }
    }

    toggleIsRowOpen = (id) => {
        this.collection.map((el) => {
            if (el.authorId === id) {
                el.isRowOpen = !el.isRowOpen
            }
        })
    }
}

export default ReportSummeryStore
