import FormStore from "../../../utils/stores/FormStore"

const initState = {
    reportStatuses: [],
    reportMonths: [],
    reportYears: [],
    dealers: []
}

const validateData = {
    reportStatuses: {required: true}
}


class ReportsSummeryFiltersStore extends FormStore {
    constructor() {
        super({initState, validateData})
    }

    clear = () => {
        this.setData(initState)
        this.clearSelectedFilters()
    }

}

export default ReportsSummeryFiltersStore