import React, { useEffect, useState } from 'react'
import ReportSummeryStore from './stores/ReportSummeryStore'
import ReportsSummeryFiltersStore from './stores/ReportsSummeryFiltersStore'
import ReportsSummaryFilters from './components/ReportsSummaryFilters'
import ReportsSummeryTable from './components/ReportsSummeryTable'
import Box from '@mui/material/Box'
import ProgressBackdrop from '../../components/ProgressBackdrop/ProgressBackdrop'
import { Provider, observer } from 'mobx-react'
import ReportsStore from '../Reports/stores/ReportsStore'
import ReportDetails from '../Reports/components/ReportDetails'

function ReportsSummery() {
    const [store] = useState(new ReportSummeryStore())
    const [filtersStore] = useState(new ReportsSummeryFiltersStore())
    const [reportsStore] = useState(() => new ReportsStore())
    const { openReportDetails, isReportDetailsOpen } = reportsStore
    useEffect(() => {
        store.load()
    }, [])

    // если данные уже есть закрывается окно отчетов данные обновляются
    useEffect(() => {
        if (!isReportDetailsOpen && store.isReady) {
            store.makeCast()
            store.reLoad().then(() => store.addServiceProps())
        }
    }, [isReportDetailsOpen])

    // блокируем скролл при открытии деталей
    useEffect(() => {
        if (isReportDetailsOpen) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
    }, [isReportDetailsOpen])

    useEffect(() => {
        store.addServiceProps()
    }, [])

    return store.isReady ? (
        <Box>
            <Box mb={2}>
                <ReportsSummaryFilters filtersStore={filtersStore} store={store} />
            </Box>
            <ReportsSummeryTable store={store} openReportDetails={openReportDetails} />
            {isReportDetailsOpen ? (
                <ReportDetails
                    selectedReportId={reportsStore.selectedReportId}
                    onClose={reportsStore.closeReportDetails}
                    scrollIsAlreadyLocked
                />
            ) : null}
        </Box>
    ) : (
        <ProgressBackdrop local={true} isOpen={true} />
    )
}

export default observer(ReportsSummery)
