const reportsSummeryStyles = (theme) => ({
    container: {
        display: "grid",
        rowGap: "5px",
        columnGap: "10px",
        gridTemplateColumns: "repeat(7, 1fr)",
        width: "100%",
        marginBottom: "16px",
    },
    searchButton: (length) => ({
        gridColumn: length < 6 ? length + 1 : "7",
        gridRow: length < 6 ? `1/${length + 1}` : "1/7"
    }),
    clearButton: (length) => ({
        gridColumn: length < 6 ? length + 2 : "7",
        gridRow: length < 6 ? `1/${length + 2}` : "2/7"
    }),
    selectTextContainer: {
        overflow: "hidden"
    },
    selectText: {
        overflow: "hidden",
        textOverflow: "ellipsis"
    }
})

export default reportsSummeryStyles