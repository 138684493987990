import moment from "moment"

export const getYears = () => {
    const years = []
    const firstYear = 2022
    const lastYear = +moment().year()
    const difference = lastYear - firstYear
    years.push(firstYear)
    if (difference > 1) {
        for (let i = 0; i < difference - 1; i++) {
            years.push(firstYear + i + 1)
        }
    }
    years.push(lastYear)
    return years
}
